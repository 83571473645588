// import MachineImage from '../Images/machine.jpeg'
import Komori from '../Images/Komori.jpg'
import Shinohara from '../Images/Shinonara.jpg'
import Komori2 from '../Images/Komori2.jpg'
import Ryobi from '../Images/Ryobi.jpg'
import Heidelberg from '../Images/Heilderberg.jpg'
import Komori3 from '../Images/Komori3.jpg'
import CTP from '../Images/CTP.jpg'
import Ryobi2 from '../Images/Ryobi2.jpg'
import image2 from '../Images/Komori1.1.jpg'
import image3 from '../Images/Komori1.2.jpg'
import image5 from '../Images/Shinohara1.1.jpg'
import image6 from '../Images/Shinohara1.2.jpg'
import image8 from '../Images/Komori2.1.jpg'
import image9 from '../Images/Komori2.2.jpg'
import image11 from '../Images/Ryobi1.1.jpg'
import image12 from '../Images/Ryobi1.2.jpg'
import image14 from '../Images/Heidelberg1.1.jpg'
import image15 from '../Images/Heidelberg1.2.jpg'
import image17 from '../Images/Komori3.1.jpg'
import image18 from '../Images/Komori3.2.jpg'
import image20 from '../Images/CTP1.1.jpg'
import image21 from '../Images/CTP1.2.jpg'
import image23 from '../Images/Ryobi2.1.jpg'
import image24 from '../Images/Ryobi2.2.jpg'

export const Machines =[
    {
        id:1,
        img:Komori,
        name:'Komori lithrone 526 2001 sapc',
        description:"Komori L526 Year 2001 5-color Komorimatic PQC Semi Apc AMR & Pre set paper size, thickness  Blanket washer Roller washer  Impression cylinder washer  Cocking No damage on cylinders  Chromed cylinders ",
        details:"The Komori Lithrone 526 is a mid-size offset printing press, offering a printing width suitable for a variety of print formats. It is available in different configurations to accommodate specific printing requirements, such as the number of printing units and coating options.",
        condition:'Ready to Deliver',
        OtherImages:[Komori,image2,image3],
    },
    {
        id:2,
        img:Shinohara,
        name:'SHINOHARA 75-IV',
        description:"SHINOHARA 75-IV, Age: 2007, Colors: 4, Size: 55 x 75 cm. , 30 inches, Mio. of impressions: 22 mio., Straight machine, SAPC - Semi Auto Plate Changing, SCC - Shinohara Console for Ink and Register, Shinohara Alcohol Film Dampening, Baldwin Refrigeration& Recirculation, Automatic Blanket Wash-up System, Automatic Ink Roller Cleaning System, IR Dryer by Baldwin, CIP 3, Powder spray.",
        details:"The Shinohara 75-IV typically features multiple printing units, allowing for multi-color printing and the application of specialty coatings. These units work seamlessly together to produce high-quality prints with vibrant colors and sharp detail",
        condition:'For Sale',
        OtherImages:[Shinohara,image5,image6],

    },
    {
        id:3,
        img:Komori2,
        name:'1996 Komori Lithrone L628+C',
        description:"Size 20 x 28 inches, PQC off press controls, Komorimatic dampening, Cocking , Semi APC, Auto ink roller Wash, Auto Blanket wash, Chromed plate and impression cylinders, Anilox coater , IR dryer , Powder spray unit, Import from Europe., Additional: anilox coater.",
        details:"This press is designed to handle a wide range of printing tasks with precision and consistency. It is suitable for various print applications, including commercial printing, packaging, magazines, and more.",
        condition:'For Sale',
        OtherImages:[Komori2,image8,image9],

    },
    {
        id:4,
        img:Ryobi,
        name:'2006 RYOBI 684',
        description:"Size 20 x 26 inches, SAPC, Matic, OperationStand, paper size preset,Auto Blanket Cleaner, Auto Roller Cleaner,Chromed Impression Cylinder,Import machine",
        details:"he Ryobi 684 typically features multiple printing units, allowing for multi-color printing and the application of specialty coatings. These units work together seamlessly to produce high-quality prints with vibrant colors and sharp detail.",
        condition:'For Sale',
        OtherImages:[Ryobi,image11,image12],

    },
    {
        id:5,
        img:Heidelberg,
        name:'Heidelberg SM 74-6 plus coater',
        description:"Year 2000, Size 20 x 29 inches, Alcolor dampening, Straight machine , Autoplate , CP2000 , High file delivery.",
        details:" Equipped with advanced automation features, the SM 74-6 plus coater enhances productivity and reduces setup times. Automatic plate loading, ink key presets, and touchscreen controls streamline the printing process, allowing for quick setup and easy operation.",
        condition:'Price Reduced',
        OtherImages:[Heidelberg,image14,image15],

    },
    {
        id:6,
        img:Komori3,
        name:'Komori LS526',
        description:"Year2009, 5- color , PQC, AMR, KHS-AI, KMS, APC (BENDERLESS), BLANKET WASHER INK ROLLER WASHER  IMPRESSION, CYLINDER WASHER , PDC-SII, INSPECTION SYSTEM, Chromed",
        details:"Equipped with advanced automation features, the LS526 enhances productivity and reduces setup times. Automatic plate loading, ink key presets, and touchscreen controls streamline the printing process, enabling quick setup and easy operation.",
        condition:'For Sale',
        OtherImages:[Komori3,image17,image18],

    },
    {
        id:7,
        img:CTP,
        name:'CTP CREO KODAK VLF + Autoloader',
        description:"CTP Kodak Trendsetter VLF 5880 Quantum Squarespot- S speed, 2.400 Dpi resolution, 830 Nm Thermal IR sensiti, aluminum plates, Autoloader MCU, Build in 2003, New laser head changed in 2019 version 1.7, N° hours done now:  2.498, Max plate size : 2032x1473 mm., Min size 510x400 mm., Print Console Kodak + computer, Plate stacker, All accessories.",
        details:"The CTP Creo Kodak VLF system delivers fast plate imaging speeds, enabling quick turnaround times and high-volume plate production. Its efficient workflow management capabilities help streamline the prepress process, optimizing overall production efficiency.",
        condition:'Available Immediately',
        OtherImages:[CTP,image20,image21],

    },
    {
        id:8,
        img:Ryobi2,
        name:'Ryobi 680',
        description:"Year 2001 Straight 4 Remote ink key control, Cocking, Remote Ink Key Control Semi Auto plate changer, RYOBIMATIC ALCOHOL DAMPENING, Max. Printing Size: 495 x 650mm Max. Paper Size: 508 x 686mm ",
        details:"The Ryobi 680 is designed to handle a wide range of printing applications with precision and consistency. It is suitable for commercial printing, packaging, labels, and specialty applications that require high-quality printing.",
        condition:'For Sale',
        OtherImages:[Ryobi2,image23,image24],

    },
]