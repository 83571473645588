import { Machines } from '../Machines'
import ServicesItem from './ServicesItem';
import { Link } from 'react-router-dom';
import ServiceSlider from "react-slick";

const Services = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false

                }
            },

            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows : false,
                    

                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>

<div className="breadcrumb">
            <div className="container">
                <div className="breadcrumb__inner">
                    <h1 className="breadcrumb__header">Our Service</h1>
                    <div className="breadcrumb__meta">
                        <a href="/" className="link">Home</a>
                        <span>Service </span>
                    </div>
                </div>
            </div>
        </div>
            <section className="blog__section ourservices_main">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section__title text-center">
                                <h3 className="subtitle text-white">UK Print Solutions</h3>
                                <h2 className="title text-white">Our Product</h2>
                            </div>
                        </div>
                    </div>
                    <div className="ourService-slider">

                        <ServiceSlider {...settings}>
                            {Machines.slice(0, 6).map((machine, index) => {
                                return (
                                    <div className="item" key={machine.id}>

                                        <ServicesItem
                                            id={machine.id}
                                            image={machine.img}
                                            title={machine.name}
                                            desc={machine.description}
                                        />
                                    </div>
                                )

                            })}
                        </ServiceSlider>

                        <div className='col-12 text-center my-5'>
                            <Link to="/services">


                                <button type='button' className='viewall'>
                                    View All Product
                                </button>
                            </Link>
                        </div>

                    </div>
                </div>
            </section>


        </>
    )
}

export default Services