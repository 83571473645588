import Banner from "../Shared/Banner"
import Counters from "../Shared/Counters"
import EnquiryForm from "../Shared/EnquiryForm"
import Map from "../Shared/Map"
import Testimonials from "../Shared/Testimonials"
import About from "./About"
import Services from "./Services"
import ImageGallery from "./Gallery"
import { useEffect } from "react"
import { useLocation } from 'react-router-dom';


const Home = () =>{
    const location = useLocation();
    useEffect(() => {  
        const currentPath = location.pathname;
        const isHomePage = currentPath === '/' || currentPath === '/home';

        if (isHomePage) {
            document.body.classList.add('homepage');
          } else {
            document.body.classList.remove('homepage');
          }
      
          // Clean up by removing the class when the component unmounts
          return () => {
            document.body.classList.remove('homepage');
          };
}, [location.pathname]);
    return(
<>
<Banner />
<About />
<Services />
<ImageGallery />
<Testimonials />
<Counters />
<EnquiryForm />
<Map />
</>
    )
}

export default Home