import { Link } from "react-router-dom"

const Footer =({ isActive , handleClosestBtnClick })  =>{

    return(
        <>
        
      
        <footer className="footer__section bg-footer">
            <div className="footer__top">
                <div className="container footer__top__content">
                    <div className="row align-items-center">
                        <div className="col-sm-8">
                            <h2 className="cta__title text-center text-sm-start">Start Your Journey to Better Business</h2>
                        </div>
                        <div className="col-sm-4 text-center text-sm-end">
                            <Link to="#" className="btn btn-primary cta__btn">Get In Touch</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                        <div className="footer__wrapper">
                            <div className="footer__widget">
                                <div className="title">
                                    <Link to="#" className="footer__logo">
                                       <img src="assets/images/logo.png" />
                                    </Link>
                                 
                                </div>
                                <p>Welcome to uk Print Solution, your trusted destination for buying and selling offset printing machines and equipment. With a focus on quality and reliability......
                                </p>

                                <ul className="social__links footer__social">
                                    <li><Link to="#instagram"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="#dribble"><i className="fa fa-dribbble"></i></Link></li>
                                    <li><Link to="#twitter"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="#youtube"><i className="fa fa-youtube"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer__wrapper">
                            <div className="footer__widget">
                                <div className="title">
                                    <h5>Services</h5>
                                </div>
                                <ul>
                                    <li><Link to="#">Offset Printing Equipments</Link></li>
                                    <li><Link to="#">Comprehensive Maintenance</Link></li>
                                    <li><Link to="#">Expert Consultation</Link></li>
                                    <li><Link to="#">Precision Repairs</Link></li>
                                    <li><Link to="#">On-Site Support</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6">
                        <div className="footer__wrapper">
                            <div className="footer__widget">
                                <div className="title">
                                    <h5>Categories</h5>
                                </div>
                                <ul>
                                    <li><Link to="#">Sustainability</Link></li>
                                    <li><Link to="#">Careers</Link></li>
                                    <li><Link to="#">Media</Link></li>
                                    <li><Link to="#">Services</Link></li>
                                    <li><Link to="#">Products</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-6 col-xs-6">
                        <div className="footer__wrapper">
                          
                            <div className="footer__widget">
                                <div className="title">
                                    <h5>Contact Us</h5>
                                </div>
                                <ul className="header__address footer__address">
                                    <li className="address__list">
                                        <div className="address__icon"><img src="assets/images/email.png" alt="email" /></div>
                                        <div className="address__desc"><Link to="#">sales@ukprintsolution.com</Link></div>
                                    </li>
                                    <li className="address__list">
                                        <div className="address__icon"><img src="assets/images/support.png" alt="support" /></div>
                                        <div className="address__desc"><Link to="#"> +91-8290380063, 9352080042</Link></div>
                                    </li>
                                    <li className="address__list">
                                        <div className="address__icon"><img src="assets/images/location.png" alt="location" /></div>
                                        <div className="address__desc">G-184, kanak vrindavan, Lalarpura, Gandhi Path(West), Jaipur</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer__content text-center">
                                <div className="content">
                                    <p>
                                        Copyright @ 2024
                                        <Link to="#"> UK Print Solution </Link>. All rights reserved
                                    </p>
                                </div>
                                <div className="link">
                                    <Link to="#">Terms &amp; Conditions</Link>
                                    <Link to="#">Privacy Policy</Link>
                                    <Link to="#">Privacy Policy</Link>
                                    <Link to="#">Disclaimer</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        

        
        <div className={`flyoutMenu ${isActive ? 'active' : ''}`} >
            <div className="flyout__flip">
                <div className="flyout__inner">
                    <div className="menu__header-top">
                        <div className="brand__logo">
                           <Link to="#"><img src="assets/images/logo.png" alt="logo" /></Link>
                        </div>
                      
                        <div className="closest__btn" onClick={handleClosestBtnClick }>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M6 6L18 18" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          
                        </div>
                    </div>
                   
                    <div className="flyout__menu">
                        <ul className="flyout-main__menu">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About Us</Link></li>
                           
                            <li className="has__dropdown">
                                <Link to="#" className="nav__link"> Manufacturer</Link>
                               
                                <ul className="sub__menu">
                                    <li><Link to="#">Services</Link></li>
                                    <li><Link to="#">Service Single</Link></li>
                                </ul>
                               
                            </li>

                            <li className="has__dropdown">
                                <Link to="javascript:void(0)" className="nav__link"> Machines</Link>
                               
                                <ul className="sub__menu">
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/services">Service Single</Link></li>
                                </ul>
                               
                            </li>
                            <li>
                                <Link to="/gallery">Gallery</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
       

        
    
       
        <button className="scroll-top scroll-to-target open" data-target="html">scroll</button>
    
        
       
       
        
        </>
    )
}

export default Footer