import {BrowserRouter as Router, Route,Routes} from "react-router-dom"
import './App.css';
import Header from "./components/Shared/Header";
import Footer from "./components/Shared/Footer";
import Home from "./components/Papes/Home";
import Services from "./components/Papes/Services";
import ServicesDetails from "./components/Papes/ServicesDetails";
import { useState } from "react";
import ContactUS from "./components/Papes/Contact";
import About from "./components/Papes/About";
import ImageGallery from "./components/Papes/Gallery";

function App() {

  const [isMenuActive, setIsMenuActive] = useState(false);

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  const handleClosestBtnClick = () => {
    setIsMenuActive(false); // Set isActive to false to remove the active class
  };
  
  return (
    <Router>

      <Header toggleMenu={toggleMenu}/>
      
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/machine/:id" element={<ServicesDetails/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/home" element={<Home/>} />
      <Route path="/contact" element={<ContactUS/>} />
      <Route path="/gallery" element={<ImageGallery/>} />
      </Routes>
      {/* <Navbar/>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<AboutUsPage/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/machine/:id" element={<MachineDetail/>} />
      

      </Routes>
      <Footer/> */}

      <Footer isActive={isMenuActive} handleClosestBtnClick={handleClosestBtnClick}/>
      
      </Router>
  );
}

export default App;
