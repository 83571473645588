import { Link } from "react-router-dom"

const Header = ({ toggleMenu }) => {
    return (
        <>


            <header className="header header">
                <div className="header__topBar">
                    <div className="container">
                        <div className="header__area">
                            <div className="header__logo d-lg-block d-none">
                                <Link to="/">
                                    <img src="../assets/images/logo.png" alt="logo" />
                                </Link>
                            </div>
                            <ul className="header__address">
                                <li className="address__list">
                                    <div className="address__icon"><img src="assets/images/email.png" alt="email" /></div>
                                    <div className="address__desc"><Link to="#">sales@ukprintsolution.com</Link></div>
                                </li>
                                <li className="address__list">
                                    <div className="address__icon"><img src="assets/images/support.png" alt="support" /></div>
                                    <div className="address__desc">Help line :<Link to="#">+91-8290380063, 9352080042</Link></div>
                                </li>
                                
                            </ul>
                            <div className="header__btn">
                                <Link to="#" className="btn btn-theme">Get A Quote</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <nav className="nav">
                    <div className="container">
                        <div className="header__wrapper">
                            <div className="header__logo d-lg-none d-block">
                                <Link to="/">
                                    <img src="assets/images/logo.png" alt="logo" />
                                </Link>
                            </div>
                            <div className="header__menu">

                                <ul className="main__menu">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About Us</Link></li> 
                                   
                                    <li className="has-dropdown">
                                        <Link to="/services"> Machines</Link>

                                        <ul className="sub-menu">
                                            <li><Link to="/services">Machines</Link></li>
                                            <li><Link to="/services">Machines</Link></li>
                                        </ul>

                                    </li>
                                    <li className="has-dropdown">
                                        <Link to="#"> Manufacturer</Link>

                                        <ul className="sub-menu">
                                            <li><Link to="#">Manufacturer</Link></li>
                                            <li><Link to="#">Manufacturer</Link></li>
                                        </ul>

                                    </li>
                                    <li><Link to="/gallery">Gallery</Link></li> 
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                                {/* <!-- Main Menu End --> */}
                            </div>
                            <div className="header__meta">
                                <ul className="social__icon">
                                    <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-dribbble"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to="#"><i className="fa fa-youtube"></i></Link></li>
                                </ul>
                                {/* <!-- Header Toggle Start --> */}
                                <div className="header__toggle d-lg-none">
                                    <button className="toggler__btn" onClick={toggleMenu}>
                                        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" ></path>
                                        </svg>
                                    </button>
                                </div>
                                {/* <!-- Header Toggle End --> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            {/* <!-- Header End --> */}

        </>
    )
}

export default Header