import React from "react";
export default function ContactUS() {


  return (
    <>
        <div className="breadcrumb">
            <div className="container">
                <div className="breadcrumb__inner">
                    <h1 className="breadcrumb__header">Connect With Us</h1>
                    <div className="breadcrumb__meta">
                        <a href="/" className="link">Home</a>
                        <span>Contact</span>
                    </div>
                </div>
            </div>
        </div>
        <section className="contact-top-section bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 m-auto">
                        <div className="section__title text-center">
                            <h2 className="title text-uppercase">Need help contact us</h2>
                            <p className="desc"></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact__box--wrapper">
                            <div className="icon">
                                <img src="assets/images/icon_01.png" alt="icon" />
                            </div>
                            <div className="content">
                                <p><a href="tel:8290380063">+91-8290380063 </a></p>
                                <p><a href="tel:9352080042">+91-9352080042</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact__box--wrapper">
                            <div className="icon">
                                <img src="assets/images/icon_02.png" alt="icon" />
                            </div>
                            <div className="content">
                                {/* <p><a href="mailto:wingsprint@example.com">wingsprint@example.com</a></p> */}
                                <p><a href="mailto:sales@ukprintsolution.com">sales@ukprintsolution.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact__box--wrapper">
                            <div className="icon">
                                <img src="assets/images/icon_03.png" alt="icon" />
                            </div>
                            <div className="content">
                                <p>G-184, kanak vrindavan, Lalarpura, Gandhi Path(West), Jaipur</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="contact-form-section bg-color pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 m-auto">
                        <div className="section__title text-center">
                            <h2 className="title text-uppercase">Fill free Enquiry Form</h2>
                            <p className="desc"></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form action="#" className="form__wrapper">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control" required="" placeholder="Name*" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="email" name="email" className="form-control" required="" placeholder="Email*" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea name="area" cols="30" rows="5" className="form-control" placeholder="your massage"></textarea>
                                    </div>
                                </div>
                                <div className="col-12 text-left">
                                    <button type="submit" className="btn btn-primary mt-10">send a message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}