import { Link } from "react-router-dom"
const ServicesItem = ({id,image,title,desc}) =>{
return(
    <> 


<Link className='cardLink ' to ={`/machine/${id}`}>
                            <div className="blogPost text-start">
                                <div className="blogPost__image">
                                    <img src={image} alt="blog-image" />
                                </div>
                                <div className="blogPost__content">
                                  
                                    <h3 className="blogPost__title">
                                        <a href="#">{title}</a>
                                    </h3>
                                    <p className="blogPost__desc">{desc.length>120?desc.slice(0,120):desc} ...<b>Read more</b>
                                    </p>
                                </div>
                                <div className="blogPost__bottom-meta text-center">
                                   <button type="button" className="btn btn-theme">Read more</button>
                                </div>
                            </div>
                            </Link>
                       
    </>
)

}

export default ServicesItem