import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {Machines} from '../Machines'
import { motion } from 'framer-motion'
import ImageSlider from '../Shared/ImageSlider'
const ServicesDetails = () =>{
    const {id} =useParams();
    const selectedMachine =Machines.find((machine)=>machine.id === parseInt(id));
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);
    if(!selectedMachine){
        return <h1>Machine not found</h1>
    }

return(
    <> 
    <div className="breadcrumb">
            <div className="container">
                <div className="breadcrumb__inner">
                    <h1 className="breadcrumb__header">Service Details</h1>
                    <div className="breadcrumb__meta">
                        <a href="/" className="link">Home</a>
                        <span>Service Details</span>
                    </div>
                </div>
            </div>
        </div>

        <section className="service-detail__section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7">
                        <div className="service__wrapper">
                            <div className="service__feature">
                                {/* <img src="../assets/images/service-single.jpg" alt="service-details" /> */}
                                <ImageSlider 
                                  image1={selectedMachine.OtherImages[0]}
                                  image2={selectedMachine.OtherImages[1]}
                                  image3={selectedMachine.OtherImages[2]}
                                  />
                                
                            </div>
                            <h3 className="service__title">{selectedMachine.name}</h3>
                            <div className="service__content">
                            {selectedMachine.description}
                                {/* <ul>
                                    <li>Abore et dolore magna aliqua ut enim veniam</li>
                                    <li>Quis nostrud exercitation ullamco laboris aliquip</li>
                                    <li>Eiusmod tempor incididunt labore.</li>
                                    <li>Abore et dolore magna aliqua ut enim veniam</li>
                                    <li>Quis nostrud exercitation ullamco laboris aliquip</li>
                                    <li>Eiusmod tempor incididunt labore.</li>
                                </ul> */}<br /><br />
                                <p>{selectedMachine.details}</p>
                                <p>
                                    <a href="#" className="btn btn-primary">Order Now</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5">
                        <div className="sidebar sticky-top">
                         
                            <div className="widget">
                                <h4 className="widget__title">More Services</h4>
                                <div className="widget__content">
                                    <ul>
                                        <li><a href="#" className="active">Offset Printing Equipments</a></li>
                                        <li><a href="#">Comprehensive Maintenance</a></li>
                                        <li><a href="#">Expert Consultation</a></li>
                                        <li><a href="#">Precision Repairs</a></li>
                                        <li><a href="#">On-Site Support</a></li>
                                        {/* <li><a href="#">Cover Van Design</a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="widget">
                                <h4 className="widget__title">Find your Service</h4>
                                <div className="widget__content">
                                    <form action="#" method="post" className="form__wrapper">
                                    <div className="form-group">
                                        <label htmlFor="f_name">Name</label>
                                        <input type="text" name="name" className="form-control" id="f_name" placeholder="your name" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="f_email">email</label>
                                        <input type="email" name="email" className="form-control" id="f_email" placeholder="your email" />
                                    </div>
                                    
                                    
                                    <div className="submit__btn">
                                        <button type="submit" className="btn btn-primary mt-15">Get Services</button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
                       
    </>
)

}

export default ServicesDetails