import React, { useState, useEffect } from 'react';

const Counters = () =>{
    const [counters, setCounters] = useState({
        clients: 0,
        designs: 0,
        satisfaction: 0,
        experience: 0,
      });
    
      useEffect(() => {
        const interval = setInterval(() => {
          // Update the counters
          setCounters((prevCounters) => ({
            clients: prevCounters.clients < 165 ? prevCounters.clients + 1 : 165,
            designs: prevCounters.designs < 650 ? prevCounters.designs + 1 : 650,
            satisfaction: prevCounters.satisfaction < 100 ? prevCounters.satisfaction + 1 : 100,
            experience: prevCounters.experience < 5 ? prevCounters.experience + 1 : 5,
          }));
        }, 300); // Update counters every 1000 milliseconds (1 second)
    
        // Clean up the interval on component unmount
        return () => clearInterval(interval);
      }, []); // Empty dependency array means the effect runs once when the component mounts
    
    return(<>
    
    <section className="counter__section ">
      <div className="container">
        <div className="row">
        <div className="section__title text-center">
                            <h3 className="subtitle">UK Print Solution</h3>
                            <h2 className="title">Happy Client's</h2>
                        </div>
          <div className="col-12 ">
            <div className="funfact__wrapper bg-color1">
              <div className="counterBox__item">
                <span className="counter">{counters.clients}</span>
                <h5 className="title">Happy Clients</h5>
              </div>
              <div className="counterBox__item">
                <span className="counter">{counters.designs}</span>
                <h5 className="title">Awesome Unique Designs</h5>
              </div>
              <div className="counterBox__item">
                <span className="counter">{counters.satisfaction}</span>
                <h5 className="title">Satisfaction Guaranteed</h5>
              </div>
              <div className="counterBox__item border-none">
                <span className="counter">{counters.experience}</span>
                <h5 className="title">Years Experiences</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>)
}


export default Counters