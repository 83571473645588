
import TestimonialsSlider from "react-slick";

const Testimonials = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <>



            <section className="testi__section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 my-3">
                            <div className="section__title v2 ">
                                <h3 className="subtitle">Testimonials</h3>
                                <h2 className="title">Help us improve our productivity</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="testimonial">
                                <TestimonialsSlider {...settings}>
                                    <figure>
                                        <blockquote>I recently purchased a Heidelberg Offset Printing Machine from this company, and I couldn't be happier with my decision.
                                            <div className="arrow"></div>
                                        </blockquote>
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
                                        <div className="author">
                                            <h5>Sarah Johnson <span></span></h5>
                                        </div>
                                    </figure>
                                    <figure>
                                        <blockquote>I've been relying on this company for all my printing machinery needs for years now, and they never disappoint. From their extensive inventory to their top-notch repair services, they've consistently exceeded my expectations. 
                                            <div className="arrow"></div>
                                        </blockquote>
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
                                        <div className="author">
                                            <h5>Pelican Steve <span></span></h5>
                                        </div>
                                    </figure>
                                    <figure>
                                        <blockquote>As a small business owner, finding a trustworthy supplier for printing machinery was crucial for the success of my venture. I'm so grateful to have found this company. Not only did they help me choose the right machine for my needs, but their ongoing support and maintenance services have been invaluable.
                                            <div className="arrow"></div>
                                        </blockquote>
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
                                        <div className="author">
                                            <h5>Emily Thompson<span> </span></h5>
                                        </div>
                                    </figure>
                                    <figure>
                                        <blockquote>I had the pleasure of working with this company when I needed urgent repairs for my printing machine. Their team responded promptly and efficiently, diagnosing the issue and providing a swift solution that minimized downtime for my business
                                            <div className="arrow"></div>
                                        </blockquote>
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
                                        <div className="author">
                                            <h5>Michael Nguyen <span></span></h5>
                                        </div>
                                    </figure>
                                    <figure>
                                        <blockquote>From start to finish, my experience with this company has been nothing short of exceptional. Their transparent approach to transactions instilled confidence in me from the beginning, and their commitment to quality was evident in the machine I purchased.
                                            <div className="arrow"></div>
                                        </blockquote>
                                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sq-sample3.jpg" alt="sq-sample3" />
                                        <div className="author">
                                            <h5>Lisa Adams <span></span></h5>
                                        </div>
                                    </figure>
                                </TestimonialsSlider>

                            </div>
                        </div>
                    </div>
                </div>
            </section >

        </>
    )
}

export default Testimonials