import React from "react";

import { Machines } from '../Machines'
export default function ImageGallery() {


  return (
    <>  <div className="breadcrumb">
    <div className="container">
        <div className="breadcrumb__inner">
            <h1 className="breadcrumb__header">Gallery</h1>
            <div className="breadcrumb__meta">
                <a href="/" className="link">Home</a>
                <span>Gallery</span>
            </div>
        </div>
    </div>
</div>
      <section className="">
        <div className="container">
          <div className="row">
          <div className="col-12">
                            <div className="section__title text-center">
                                <h3 className="subtitle">Uk Print Solution</h3>
                                <h2 className="title">Out Photo Gallery</h2>
                            </div>
                        </div>
                        <div className="col-12">

                        
          <div className="img-gallery-wrapper" >
          {Machines.slice(0, 6).map((machine, index) => {
                                return (
                                
                                  <div className="img-gallery" key={machine.id}>
                                    <img src={machine.img} alt="" />
                                    <div className="overlay">
                                    <h1 className="text-h1">UK Print Solution</h1>
                                      <p className="text-p">
                                      Used Quality Offset Printing Equipments
                                       </p>
                                      <a className="btn btn-theme small" href="#"> Read More</a>
                                    </div>
                                  </div>
                               
                                )

                            })}
            </div>
          </div></div>
        </div>
      </section>
      
    </>
  );
}