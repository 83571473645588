// ImageSlider.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const ImageSlider = ({image1,image2,image3}) => {


    const images = [
        image1,image2,image3,
      ];
  return (
    <>
     <Carousel autoPlay infiniteLoop showThumbs={false} interval={3000} showStatus={false}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default ImageSlider;
