const About = () =>{
    return(
        <>  <div className="breadcrumb">
        <div className="container">
            <div className="breadcrumb__inner">
                <h1 className="breadcrumb__header">About Our Company</h1>
                <div className="breadcrumb__meta">
                    <a href="/" className="link">Home</a>
                    <span>About</span>
                </div>
            </div>
        </div>
    </div>
        
        <section className="about__section bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section__title text-start">
                            <h3 className="subtitle">About Us</h3>
                            <h2 className="title">We are Used  Printing Machine Supplier- Buy Used Heidelberg Offset Printing Machine</h2>
                            <p className="desc">
                          UK print solutions  is one of the biggest Used Heidelberg Printing Machine Supplier in India, we are selling  heidelberg offset printing machine to leading print houses in India.
                            </p>
                        </div>
                        <div className="iconBox v2">
                            <div className="iconBox__icon">
                                <img src="assets/images/expert-energy.svg" alt="expert-energy icon" />
                            </div>
                            <div className="iconBox__content">
                                <h3 className="title"><a href="#">What Defines Us</a></h3>
                                <p className="desc">
                                At our core, we are more than just suppliers of used printing machines; we are pioneers of efficiency, reliability, and trust. Our commitment to excellence has solidified our reputation as the go-to source for top-tier printing equipment, particularly renowned brands like Heidelberg Offset Printing Machines.
                                </p>
                            </div>
                        </div>
                        <div className="iconBox v2">
                            <div className="iconBox__icon">
                                <img src="assets/images/expert-energy.svg" alt="expert-energy icon" />
                            </div>
                            <div className="iconBox__content">
                                <h3 className="title"><a href="#">Our Philosophy</a></h3>
                                <p className="desc">
                                We follow a core value i.e. strong business ethics, and work tirelessly towards customer satisfaction. Furthermore, we believe that customer happiness is of supreme value, therefore, at UK print solutions
                                 </p>
                            </div>
                        </div>
                        <a href="#" className="btn btn-theme mb-3">Read More</a>
                    </div>
                    <div className="col-lg-6">
                        <div className="about__media__wrapper">
                            <div className="about__media">
                                <img src="assets/images/abt1.jpg" alt="about 1" />
                            </div>
                            <div className="about__media">
                                <img src="assets/images/abt2.jpg" alt="about 1" />
                            </div>
                            <div className="about__media">
                                <img src="assets/images/abt3.jpg" alt="about 1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
            
        
        </>
    )
    }
    
    export default About