const Banner = () =>{
return(
    <>
    
  
        <div className="banner">
            <div className="container">
                <div className="banner__inner">
                    <h3 className="banner__subheader">We Deals In :</h3>
                    <h1 className="banner__header">Used Quality Offset Printing Equipments</h1>
                    <p className="banner__text">Building Legacies, One Machine at a Time: Our Defined Identity as Premier Suppliers of Quality Used Printing Machinery. </p>
                    <a href="#" className="btn btn-theme banner__btn">Our Pricing</a>
                </div>
            </div>
        </div>
        
    
    </>
)
}

export default Banner