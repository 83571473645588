const Map = () => {
    return (
        <>
           <div className="map">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d823.5567656389454!2d75.69515618270977!3d26.90469625922625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4b5ec5aba80f%3A0x2008d0f4acc2e5a2!2sKanak%20Vrindavan!5e0!3m2!1sen!2sin!4v1708973120486!5m2!1sen!2sin"  ></iframe>
                            </div>
         </>
    )
}

export default Map